import React, { useContext, useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { Navbar, Nav, Dropdown, Button, Form, InputGroup, Spinner, Col, Row } from "react-bootstrap";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { useError } from '../contexts/ErrorContext';

import { AuthContext } from "../contexts/AuthContext";
import { SidebarContext } from "../contexts/SidebarContext";
import { HeaderContext } from "../contexts/HeaderContext";
import { BrandContext } from "../contexts/BrandContext";
import { ToastContext } from "../contexts/ToastContext";

import CurrencyFilter from "./helper/CurrencyFilter";
import MarketplaceFilter from "./helper/MarketplaceFilter";
// import WeekstartSelect from "./helper/WeekstartSelect";


import {
    yesterday,
    p7d,
    MTD_s,
    YTD_s,
    YTD_e,
    last_MTD_s,
    last_MTD_e,
    last_YTD_s,
    last_YTD_e,
    getLastSaturday,
    getLastSunday,
} from "./helper/DateVars";
import question from '../assets/icons/svg/question.svg';
import DateRangeSelect from "./wizard/DateRangeSelect";

import Select from "react-select";
import {subDays} from "date-fns";
import logo from "../assets/logo.svg";

const Header = ({ history }) => {
    
    const reportError = useError();

    const { collapsed, setCollapsed } = useContext(SidebarContext);
    const {
        pageTitle,
        setPageTitle,
        dataLoading,

        pageTimeframe,
        setPageTimeframe,
        setDates,
        selectedDefaultDates,
        getDefaultDates,
        verifyDates,
        toMomentDateStr,

        selectedPreviousCustomDates,
        setSelectedPreviousCustomDates,

        pageMarketplace,
        setPageMarketplace,
        comparativeOptions,
        comparativeValue,
        setComparativeValue,
    } = useContext(HeaderContext);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    const [displayStartDate, setDisplayStartDate] = useState(selectedDefaultDates?.tf_s);
    const [displayEndDate, setDisplayEndDate] = useState(selectedDefaultDates?.tf_e);
    const [tp_displayStartDate, setTPDisplayStartDate] = useState(selectedDefaultDates?.tp_s);
    const [tp_displayEndDate, setTPDisplayEndDate] = useState(selectedDefaultDates?.tp_e);
    const [displayTimeFrame, setDisplayTimeFrame] = useState("daily");
    const [displayDateLength, setDisplayDateLength] = useState(90);
    const [showCustomComparativeSelector, setShowCustomComparativeSelector] = useState("hide");

    // Render
    const [pageRender, setPageRender] = useState(null);
    const [infoPopUp, setInfoPopUp] = useState(false);

    const [showDropdown, setShowDropdown] = useState(() => {return false});

    // Responsive Vars
    const dateDropDownWidth = 500;

    const isMobile = useMediaQuery(
        { maxWidth: 768 },
    );

    const xsDateDropdown = useMediaQuery(
        { maxWidth: dateDropDownWidth + 75 + (!collapsed ? 270 : 0) },
    );
    const [dateFilterResponsive, setDateFilterResponsive] = useState(xsDateDropdown ? true : false);

    const location = useLocation();

    useEffect(() => {
        document.title = `${pageTitle} | ${brand.brandName}`;
    }, [pageTitle, brand]);

    const setDisplayDates = (params) => {
        if (verifyDates(params) === true) {
            setDisplayStartDate(params?.tf_s);
            setDisplayEndDate(params?.tf_e);
            setTPDisplayStartDate(params?.tp_s);
            setTPDisplayEndDate(params?.tp_e);
            setDisplayTimeFrame(params?.timeframe);
        }
    };

    useEffect(() => {
        //On Page Change -> Reset Header Inputs to match the underlying value
        setPageTitle(process.env.REACT_APP_TITLE); // Default
        setPageTimeframe("timeframe"); // Default
        setPageMarketplace(true);

        if (Object.keys(selectedDefaultDates).length === 0 || selectedDefaultDates === undefined) {
            const defaults = getDefaultDates();
            setDates(defaults);
            setDisplayDates(defaults);
        } else {
            setDisplayDates(selectedDefaultDates);
        }
    }, [location, setPageTitle, pageMarketplace]);

    const updateHeaderPageMarketplace = (value) => {
        // setPageMarketplace(value);
    }

    const filterSubmit = () => {
        const newDate = {
            tf_s: displayStartDate,
            tf_e: displayEndDate,
            tp_s: tp_displayStartDate,
            tp_e: tp_displayEndDate,
            timeframe: displayTimeFrame,
        };

        if (verifyDates(newDate) === false) {
            toast("Invalid Date");
        } else {
            setDates(newDate);
        }
    };

    const timeFrameToDisplay = (tf) => {
        if (tf === "daily") return "daily";
        if (tf === "wow") return "Weekly";
        if (tf === "mom") return "Monthly";
        if (tf === "quarter") return "Quarterly";
        if (tf === "annual") return "Yearly";
        return tf;
    };

    const displayDateDropdown = (start, end, timeframe = undefined) => {
        return (
            <span className="mr-2">
                {filterDisplayDate(start)} - {filterDisplayDate(end)}
                {/*{timeframe !== undefined ? <span className="ml-1">{timeFrameToDisplay(timeframe)}</span> : ""}*/}
            </span>
        );
    };

    const filterDisplayDate = (date) => {
        // return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

        // Format the date
        const year = date.format('YYYY'); // year as four digits
        const month = date.format('MMM'); // month as a three-letter abbreviation
        const day = date.format('DD'); // day as two digits

        return `${month} ${day} ${year}`;

    };

    const onTimeframeQuickChange = (newValue) => {
        // console.log('onTimeframeQuickChange', newValue);
        if (newValue !== "") {
            switch (newValue) {
                case "lw-d":
                    setDisplayStartDate(p7d);
                    setDisplayEndDate(yesterday);
                    setDisplayTimeFrame("daily");
                    break;

                case "mtd-d":
                    setDisplayStartDate(MTD_s);
                    setDisplayEndDate(yesterday);
                    setDisplayTimeFrame("daily");
                    break;

                case "ytd-w":
                    setDisplayStartDate(getLastSunday(YTD_s));
                    setDisplayEndDate(getLastSaturday(yesterday));
                    setDisplayTimeFrame("wow");
                    break;

                case "ytd-m":
                    setDisplayStartDate(YTD_s);
                    setDisplayEndDate(yesterday);
                    setDisplayTimeFrame("mom");
                    break;

                case "yoy-w":
                    setDisplayStartDate(getLastSunday(YTD_s));
                    setDisplayEndDate(getLastSaturday(YTD_e));
                    setDisplayTimeFrame("wow");
                    break;

                case "yoy-m":
                    setDisplayStartDate(YTD_s);
                    setDisplayEndDate(YTD_e);
                    setDisplayTimeFrame("mom");
                    break;

                case "lifetime": // Lifetime, use brand start date
                    if (brand?.brandLaunchDate !== undefined && brand?.brandLaunchDate !== null) {
                        setDisplayStartDate(moment(brand.brandLaunchDate));
                    }
                    else {
                        setDisplayStartDate(YTD_s);
                    }
                    setDisplayEndDate(yesterday);
                    break;

                default:
                    break;
            }
        }
    };

    const onSnapshotQuickChange = (newValue) => {
        // console.log('onSnapshotQuickChange', newValue);
        if (newValue !== "") {
            switch (newValue) {
                case "wtd": // Week to Date
                    setTPDisplayStartDate(getLastSunday());
                    setTPDisplayEndDate(yesterday);
                    break;

                case "lw": // Last Week
                    setTPDisplayStartDate(p7d);
                    setTPDisplayEndDate(yesterday);
                    break;

                case "mtd": // Month to Date
                    setTPDisplayStartDate(MTD_s);
                    setTPDisplayEndDate(yesterday);
                    break;

                case "lm": // Last Month
                    setTPDisplayStartDate(last_MTD_s);
                    setTPDisplayEndDate(last_MTD_e);
                    break;

                case "ytd": // Year to Date
                    setTPDisplayStartDate(YTD_s);
                    setTPDisplayEndDate(yesterday);
                    break;

                case "ly": // Last Year
                    setTPDisplayStartDate(last_YTD_s);
                    setTPDisplayEndDate(last_YTD_e);
                    break;

                default:
                    break;
            }
        }
    };

    const displayApplyButton = () => {
        return (
            <Button
                className="align-self-center btn btn-info bg-accent-default ml-2"
                onClick={() => filterSubmit()}
                disabled={dataLoading}
            >
                {dataLoading ? (
                    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"></Spinner>
                ) : (
                    <span>Apply Time Range</span>
                )}
            </Button>
        );
    };

    const displayCurrencyDropdown = () =>  {
        return (
            <Col className="px-1">
                <Col className="px-1">
                    <span className="body-1 text-enalytics-neon align-self-center">Currency</span>
                </Col>
                <Col className="px-1" style={{ minWidth: "10em" }}>
                    <CurrencyFilter></CurrencyFilter>
                </Col>
            </Col>
        )
    }

    const displayMarketplaceDropdown = () =>  {
        switch (pageMarketplace) {
            default:
            case true:
                return (
                    <Col className="px-1">
                        <Col className="px-1">
                            <span className="body-1 text-enalytics-neon align-self-center">Marketplace</span>
                        </Col>
                        <Col className="px-1" style={{ minWidth: "12em" }}>
                            <MarketplaceFilter 
                                pageMarketplace={pageMarketplace}
                                onChange={(value) => updateHeaderPageMarketplace(value)}
                            ></MarketplaceFilter>
                        </Col>
                    </Col>
                )
                // break;

            case false:
                return ('');
                // break;
        }
        
    }

    // const displayWeekstartDropdown = () =>  {
    //     return (
    //         <Row>
    //             <span className="body-1 text-dark align-self-center">Weekday Start</span>
    //             <Col className="" style={{ minWidth: "10em" }}>
    //                 <WeekstartSelect></WeekstartSelect>
    //             </Col>
    //         </Row>
    //     )
    // }

    useEffect(() => {
        // console.log('DateFilter:', xsDateDropdown);
        if ((isMobile && !collapsed) || xsDateDropdown) {
            setDateFilterResponsive(true);
        }
        else {
            setDateFilterResponsive(false);
        }
    }, [isMobile, collapsed, xsDateDropdown])


    const toggleDropdown = (nextShow) => {
        setShowDropdown(nextShow);
    }

    const displaySnapshot = () => {
        return (
            <Navbar.Collapse className="justify-content-end">
                <Nav className="align-items-center">
                    {displayMarketplaceDropdown()}
                    {displayCurrencyDropdown()}
                    <Col className="px-1">
                        <Col className="px-1">
                            <span className="body-1 text-enalytics-neon align-self-center">Time Range</span>
                        </Col>
                        <Col className="px-1">
                            <Dropdown id="dropdown-snapshot-container" show={showDropdown} onToggle={toggleDropdown}>
                                <Dropdown.Toggle
                                    id="dropdown-snapshot"
                                    className="w-100 bg-white text-dark body-1 border-0"
                                >
                                    {displayDateDropdown(tp_displayStartDate, tp_displayEndDate)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="right" id="dropdown-snapshot-menu">
                                    <div className="w-100 p-2">
                                        <DateRangeSelect onSelectDate={selectDates} onChange={() =>{console.log("changed")}} />
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Col>
                    {comparativeSelection()}
                </Nav>
            </Navbar.Collapse>
        )
    }

    const selectDates = (dates) => {
        if(dates[0].startDate !== null && dates[0].startDate !== undefined){
            if(pageTimeframe === "timeframe"){
                setDisplayStartDate(moment(dates[0].startDate));
                setDisplayEndDate(moment(dates[0].endDate));
            }

            if(pageTimeframe === "snapshot"){
                setTPDisplayStartDate(moment(dates[0].startDate));
                setTPDisplayEndDate(moment(dates[0].endDate));
            }


            const newDate = {
                tf_s: moment(dates[0].startDate),
                tf_e: moment(dates[0].endDate),
                tp_s: moment(dates[0].startDate),
                tp_e: moment(dates[0].endDate),
                timeframe: displayTimeFrame,
            };

            if (verifyDates(newDate) === false) {
                toast("Invalid Date");
            } else {
                setDates(newDate);
            }
            toggleDropdown()
        }
    }

    const selectPreviousDates = (dates) => {
        const newDate = {
            pc_s: moment(dates[0].startDate).format('YYYY-MM-DD'),
            pc_e: moment(dates[0].endDate).format('YYYY-MM-DD'),
        };

        setSelectedPreviousCustomDates(newDate);
        // setComparativeValue({label: 'Custom Date', value: 'pc'});
        hideCustomCompare();
    }

    const checkTimeFrame = (dates) => {
        // let dayLength = moment().diff(moment(dates.startDate),'days');
        // setDisplayDateLength(dayLength);
        // if(dayLength < 60){
        //     setDisplayTimeFrame('daily');
        // }
        // else if(dayLength > 60){
        //     setDisplayTimeFrame('mom');
        // }

    }


    const displayTimeRange = () => {
        return (
            <Navbar.Collapse className="justify-content-end">
                <Nav className="align-items-center">
                    {displayMarketplaceDropdown()}
                    {displayCurrencyDropdown()}
                    <Col className="px-1">
                        <Col className="px-1">
                            <span className="body-1 text-enalytics-neon align-self-center">Time Range</span>
                        </Col>
                        <Col className="px-1">
                            <Dropdown id="dropdown-timeframe-container" show={showDropdown} onToggle={toggleDropdown}>
                                <Dropdown.Toggle
                                    id="dropdown-timeframe"
                                    className="w-100 bg-white text-dark body-1 border-0"
                                >
                                    {displayDateDropdown(displayStartDate, displayEndDate, displayTimeFrame)}
                                </Dropdown.Toggle>

                                <Dropdown.Menu align="right"  id="dropdown-timeframe-menu">
                                    <div className="w-100 p-2">
                                        <DateRangeSelect onSelectDate={selectDates} onChange={checkTimeFrame} />
                                    </div>
                                    <div className="interval-select-container">
                                        {(pageTitle === "Quantitative Report" || pageTitle === "Bundles Performance" || pageTitle === "Brand Summary")    && (
                                            <InputGroup>
                                                <Form.Control
                                                    as="select"
                                                    value={displayTimeFrame}
                                                    onChange={(e) => setDisplayTimeFrame(e.target.value)}
                                                >

                                                    <option value="daily">Daily</option>
                                                    <option value="wow">Weekly</option>
                                                    <option value="mom">Monthly</option>
                                                    <option value="quarter">Quarterly</option>
                                                    <option value="annual">Yearly</option>

                                                    {/*{displayDateLength > 14 && (*/}
                                                    {/*    <option value="wow">Weekly</option>)}*/}
                                                    {/*{displayDateLength > 60 && (*/}
                                                    {/*    <option value="mom">Monthly</option>)}*/}
                                                    {/*{displayDateLength > 100 && (*/}
                                                    {/*    <option value="quarter">Quarterly</option>)}*/}
                                                    {/*{displayDateLength > 366 && (*/}
                                                    {/*    <option value="annual">Yearly</option>)}*/}

                                                </Form.Control>
                                            </InputGroup>

                                        )}

                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Col>
                    {comparativeSelection()}
                </Nav>
            </Navbar.Collapse>
        )
    }

    const onComparativeChange = (option) =>{
        if(option.value === 'pc'){
            //show date picker
            setShowCustomComparativeSelector("show");
        }

        setComparativeValue(option);
    }

    const hideCustomCompare = () => {
        setShowCustomComparativeSelector("hide")
    }
    const divRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (divRef.current && !divRef.current.contains(event.target)) {
                hideCustomCompare();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [hideCustomCompare]);

    const comparativeSelection = () => {
        if(pageTitle === "Paid Media Overview" || pageTitle === "Brand Summary"){
            return (
                <Col className="px-1">
                    <Col className="px-1">
                        <span className="body-1 text-enalytics-neon align-self-center">
                            Comparative Time Range
                        </span>
                    </Col>
                    <Col className="px-1" style={{ minWidth: "12em" }}>
                        <Select
                            className="text-dark w-100 rounded-0 select-border-0"
                            placeholder="Select Date to Compare"
                            value={comparativeValue}
                            options={comparativeOptions}
                            onChange={(option) => onComparativeChange(option)}
                            name="compareSelect"
                            isMulti={false}
                            allowSelectAll={false}
                            isSearchable={false}
                            isLoading={false}
                        />
                    </Col>
                    <div className={`dropdown-menu hide dropdown-menu-right ` + showCustomComparativeSelector} ref={divRef}>
                        <div className="w-100 p-2">
                            <DateRangeSelect onSelectDate={selectPreviousDates} onChange={() =>{console.log("changed")}} />
                        </div>
                    </div>
                </Col>
            )
        }
    }

    const renderHeader = () => {
        switch (pageTimeframe) {
            case "currency":
                return (
                    <Navbar.Collapse className="justify-content-end">
                    <Nav className="align-items-center">
                            {displayMarketplaceDropdown()}
                            {displayCurrencyDropdown()}
                        </Nav>
                    </Navbar.Collapse>
                );
            case "snapshot":
                return (
                    <div className="py-1 d-flex" style={!dateFilterResponsive ? {flexGrow: 1} : {}}>
                        {displaySnapshot()}
                    </div>
                );
            case "timeframe":
                return (
                    <div className="py-1 d-flex" style={!dateFilterResponsive ? {flexGrow: 1} : {}}>
                        {displayTimeRange()}
                    </div>
                );
            case "weekstart":
                return (
                    <Navbar.Collapse className="justify-content-end">
                        <Nav className="align-items-center">
                            {displayMarketplaceDropdown()}
                            {displayCurrencyDropdown()}
                            {/* {displayWeekstartDropdown()} */}
                        </Nav>
                    </Navbar.Collapse>
                );
            default:
                return null;
        }
    }

    useEffect(() => {
        try {

            setPageRender(renderHeader());

        } catch (error) {

            reportError(error);

        }
    }, [reportError,
        pageTimeframe,
        selectedDefaultDates,
        xsDateDropdown,
        displayStartDate,
        displayEndDate,
        tp_displayStartDate,
        tp_displayEndDate,
        displayTimeFrame,
        pageMarketplace,
        showDropdown,
        comparativeValue,
        showCustomComparativeSelector,
    ]);

    const toggleInfoPopUp = () => {
        if(!infoPopUp){
            setInfoPopUp(true);
        }
    };

    document.addEventListener('mousedown', () =>{
        if(infoPopUp){
            setInfoPopUp(false);
        }
    });

    const infoPopUpRender = () => {
        return (
            <div className="info-popup-container">
                <div className="info-popup">
                    <p>What's New</p>
                    <ul>
                        <li>New color layout for improved viewability</li>
                        <li>New, more intuitive date range selector + comparative date selector</li>
                        <li>Ability to view Total, Paid, and Organic sales year-over-year, as well as by Quarter
                            and Month
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    return (
        <Navbar sticky="top" style={!dateFilterResponsive ? {height: "80px"} : {flexWrap: "wrap"}}
                className="border-bottom bg-black border-bottom-0">
            <Button
                className="btn bg-transparent border-0 text-enalytics-neon px-0 mr-4"
                style={{minWidth: "18px"}}
                onClick={() => setCollapsed(!collapsed)}
            >
                <FontAwesomeIcon icon={faBars}/>
            </Button>
            <Navbar.Brand>
                <h4 className="mb-0 text-enalytics-neon">{pageTitle}</h4>
            </Navbar.Brand>
            <div style={{marginLeft: "150px"}}>
                <span className="text-enalytics-neon">What&apos;s New&nbsp;<img
                    style={{cursor: "pointer", marginTop: "-5px"}} src={question} alt="question" width="25" height="25"
                    onClick={toggleInfoPopUp}></img></span>
            </div>
            {/*{*/}
            {/*    pageTitle === true ?*/}
            {/*        (*/}
            {/*            <div style={{marginLeft: "150px"}}>*/}
            {/*                <span className="text-enalytics-neon">What&apos;s New&nbsp;<img*/}
            {/*                    style={{cursor: "pointer", marginTop: "-5px"}} src={question} alt="question" width="25"*/}
            {/*                    height="25" onClick={toggleInfoPopUp}></img></span>*/}
            {/*            </div>*/}
            {/*        ) : ''*/}
            {/*}*/}


            {infoPopUp ? (
                <div className="info-popup-container">
                    <div className="info-popup">
                        <p>What&apos;s New</p>
                        <ul>
                            <li>Prime Day reports are located under the new “Promotions” tab, where the “Subscribe &
                                Save” report will be relocated.
                            </li>
                            <li>There are three individual reports dedicated to Prime Day reporting:</li>


                            <ul>
                                <li>Event-over-Event Report</li>
                                <li>Promo vs. Non-Promo Report</li>
                                <li>ASIN-level Report</li>

                            </ul>

                            <li>Each report has a downloadable data table (.csv) in addition to a graph to aid
                                visualization.
                            </li>
                            <li>Each report highlights performance by specific day (Prime Day 1 + Prime Day 2) and can
                                also be customized to show year-over year performance (data begins at Prime Day 2022.)
                            </li>
                        </ul>
                    </div>
                </div>
            ) : ''}


            {pageRender && (pageRender)}
        </Navbar>
    );
};

export default Header;
