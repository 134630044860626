import React, { useContext, useState, useEffect } from "react";
import { Card, Tabs, Tab } from "react-bootstrap";
import BootstrapTablesWrapper from "../BootstrapTablesWrapper";
import moment from "moment";

import WidgetSpinner from "../../../components/helper/WidgetSpinner";
import { categoryPerformance, collectionPerformance } from "../../../api/brandData";
import { AuthContext } from "../../../contexts/AuthContext";
import { BrandContext } from "../../../contexts/BrandContext";
import { HeaderContext } from "../../../contexts/HeaderContext";
import { ToastContext } from "../../../contexts/ToastContext";

const categoryColumns = [
    /*0*/ {
        dataField: "category",
        text: "Category",
        sort: true,
        type: "string",
        filter: "textFilter",
        headerStyle: (colum, colIndex) => {
            return { whiteSpace: "normal", minWidth: "270px" };
        },
    },
    /*1*/ {
        dataField: "total_sales",
        text: "Total Sales",
        type: "number",
        sort: true,
        formatter: "dollarFormatter",
    },
    /*2*/ {
        dataField: "pt_total_sales",
        text: "Previous Days",
        type: "number",
        sort: true,
        formatter: "dollarFormatter",
    },
    /*3*/ {
        dataField: "previous_sales_change",
        text: "Sales Change ($)",
        type: "number",
        sort: true,
        formatter: "tableColorDollarFormatter",
    },
    /*4*/ {
        dataField: "previous_sales_change_pct",
        text: "Sales Change (%)",
        type: "number",
        sort: true,
        formatter: "percentColorFormatter",
    },
    /*5*/ {
        dataField: "total_units",
        text: "Total Units",
        type: "number",
        sort: true,
    },
    /*6*/ {
        dataField: "organic_units",
        text: "Organic Units",
        type: "number",
        sort: true,
    },
    /*7*/ {
        dataField: "paid_units",
        text: "Paid Units",
        type: "number",
        sort: true,
    },
    /*8*/ {
        dataField: "sns_units",
        text: "SNS Units",
        type: "number",
        sort: true,
    },
    /*9*/ {
        dataField: "buybox",
        text: "Buybox",
        type: "number",
        formatter: "percentFormatter",
        sort: true,
    },
    /*10*/ {
        dataField: "30d_sales",
        text: "30 Day Sales",
        type: "number",
        sort: true,
        formatter: "dollarFormatter",
        headerClasses: () => {
            return "bg-gray-light";
        },
        classes: () => {
            return "bg-gray-light";
        },
    },
];

const collectionColumns = [
    /*0*/ {
        dataField: "collection",
        text: "Collection",
        sort: true,
        type: "string",
        filter: "textFilter",
        headerStyle: (colum, colIndex) => {
            return { whiteSpace: "normal", minWidth: "270px" };
        },
    },
    /*1*/ {
        dataField: "total_sales",
        text: "Total Sales",
        type: "number",
        sort: true,
        formatter: "dollarFormatter",
    },

    /*2*/ {
        dataField: "py_total_sales",
        text: "Previous Year",
        type: "number",
        sort: true,
        formatter: "dollarFormatter",
    },
    /*3*/ {
        dataField: "previous_year_sales_change",
        text: "YOY Sales Change ($)",
        type: "number",
        sort: true,
        formatter: "tableColorDollarFormatter",
    },
    /*4*/ {
        dataField: "previous_year_sales_change_pct",
        text: "YOY Change (%)",
        type: "number",
        sort: true,
    },

    /*5*/ {
        dataField: "pt_total_sales",
        text: "Previous Days",
        type: "number",
        sort: true,
        formatter: "dollarFormatter",
    },
    /*6*/ {
        dataField: "previous_sales_change",
        text: "Sales Change ($)",
        type: "number",
        sort: true,
        formatter: "tableColorDollarFormatter",
    },
    /*7*/ {
        dataField: "previous_sales_change_pct",
        text: "Sales Change (%)",
        type: "number",
        sort: true,
        formatter: "percentColorFormatter",
    },
    /*8*/ {
        dataField: "total_units",
        text: "Total Units",
        type: "number",
        sort: true,
    },
    /*9*/ {
        dataField: "organic_units",
        text: "Organic Units",
        type: "number",
        sort: true,
    },
    /*10*/ {
        dataField: "paid_units",
        text: "Paid Units",
        type: "number",
        sort: true,
    },
    /*11*/ {
        dataField: "sns_units",
        text: "SNS Units",
        type: "number",
        sort: true,
    },
    /*12*/ {
        dataField: "buybox",
        text: "Buybox",
        type: "number",
        formatter: "percentFormatter",
        sort: true,
    },
    /*13*/ {
        dataField: "30d_sales",
        text: "30 Day Sales",
        type: "number",
        sort: true,
        formatter: "dollarFormatter",
        headerClasses: () => {
            return "bg-gray-light";
        },
        classes: () => {
            return "bg-gray-light";
        },
    },
];

const defaultSorted = [
    {
        dataField: "total_sales",
        order: "desc",
    },
];

const ProductLevelSalesTable = (props) => {
    const { token } = useContext(AuthContext);
    const { startDate, endDate, timeFrame, selectedDefaultDates, currency, marketplace } = useContext(HeaderContext);

    const [categoryData, setCategoryData] = useState();
    const [collectionData, setCollectionData] = useState();
    const [activeTabTable, setActiveTabTable] = useState("category");
    const [widgetLoading, setWidgetLoading] = useState(true);
    const { brand } = useContext(BrandContext);
    const { toast } = useContext(ToastContext);

    const {
        showConfigButton = false,
        headerTitle = "Category Level Sales",
        headerSubtitle = (<p>Sales metrics grouped by product category type (ex. Moisturizer, etc.). Shows sales metrics in dollars and units and by channel at product category level. <br/> With 30 day sales rate at end column.</p>),
        widgetData = null,
        widgetDataLoading = null,
    } = props;

    useEffect(() => {
        const getData = async () => {
            if (widgetData !== null) {
                setWidgetLoading(true);
                setCategoryData(widgetData);
                setWidgetLoading(false);
            } else {
                setWidgetLoading(true);
                try {
                    // Use snapshot / time period data
                    const resCat = await categoryPerformance(token, {
                        brandid: brand.brandId,
                        tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                        tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                        wow: selectedDefaultDates.timeframe,
                        currency: currency,
                        marketplace: marketplace,
                    });

                    setCategoryData(resCat.data);

                    const resCol = await collectionPerformance(token, {
                        brandid: brand.brandId,
                        tf_s: moment(selectedDefaultDates.tp_s).format('YYYY-MM-DD'),
                        tf_e: moment(selectedDefaultDates.tp_e).format('YYYY-MM-DD'),
                        wow: selectedDefaultDates.timeframe,
                        currency: currency,
                        marketplace: marketplace,
                    });

                    setCollectionData(resCol.data);

                    // console.log(resCat.data, resCol.data);
                    setWidgetLoading(false);
                } catch (err) {
                    setWidgetLoading(false);
                    toast(err.message);
                }
            }
        };

        getData();
    }, [
        // Context
        token,
        brand.brandId,
        startDate,
        endDate,
        timeFrame,
        toast,
        currency,
        marketplace,
        selectedDefaultDates,

        // Props
        widgetData,
        widgetDataLoading,
    ]);

    const renderCategoryTable = () => {
        let newCols = categoryColumns;
        if (categoryData?.info !== undefined) {
            if (categoryData.info?.pt !== undefined) {
                let ptTimeframeInfo = categoryData.info.pt;
                // Previous Timeframe Columns
                newCols[2].text = `Previous ${ptTimeframeInfo.diff} Days`;
                newCols[2].headerAttrs = { title: `${ptTimeframeInfo.pt_s} - ${ptTimeframeInfo.pt_e}` };
            }
            if (categoryData.info?.pm !== undefined) {
                let pmTimeframeInfo = categoryData.info.pm;
                // 30 Day Columns
                newCols[10].headerAttrs = { title: `${pmTimeframeInfo.pm_s} - ${pmTimeframeInfo.pm_e}` };
            }
            // console.log(categoryData.info, newCols);
        }

        return (
            <BootstrapTablesWrapper
                data={categoryData?.tableData}
                keyField="category"
                columns={newCols}
                defaultSorted={defaultSorted}
                tableOnly={true}
                columnModal={true}
                hasPagination={true}
                hasFilter={true}
                rowNowrap={true}
                headerNowrap={true}
            />
        );
    };

    const renderCollectionTable = () => {
        let newCols = collectionColumns;
        if (collectionData?.info !== undefined) {
            if (collectionData.info?.pt !== undefined) {
                let ptTimeframeInfo = collectionData.info.pt;
                // Previous Timeframe Columns
                newCols[5].text = `Previous ${ptTimeframeInfo.diff} Days`;
                newCols[5].headerAttrs = { title: `${ptTimeframeInfo.pt_s} - ${ptTimeframeInfo.pt_e}` };
            }
            if (collectionData.info?.pm !== undefined) {
                let pmTimeframeInfo = collectionData.info.pm;
                // 30 Day Columns
                newCols[13].headerAttrs = { title: `${pmTimeframeInfo.pm_s} - ${pmTimeframeInfo.pm_e}` };
            }
            // console.log(collectionData.info, newCols);
        }

        return (
            <BootstrapTablesWrapper
                data={collectionData?.tableData}
                keyField="collection"
                columns={newCols}
                defaultSorted={defaultSorted}
                tableOnly={true}
                columnModal={true}
                hasPagination={true}
                hasFilter={true}
                rowNowrap={true}
                headerNowrap={true}
            />
        );
    };

    return (
        <Card className="h-100">
            <Card.Header className="bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{headerTitle}</h5>
                        {headerSubtitle !== "" ? <p className="body-1 text-secondary mb-0">{headerSubtitle}</p> : ""}
                    </div>
                </div>
            </Card.Header>

            <Card.Body className="pb-0" style={{ minHeight: props.minHeight ?? "500px" }}>
                {widgetLoading ? (
                    <div className="w-100 h-100">
                        <WidgetSpinner />
                    </div>
                ) : (
                    <div className="w-100">
                        { collectionData?.tableData.length > 1 ?
                            (
                                <Tabs
                                    activeKey={activeTabTable}
                                    onSelect={(k) => setActiveTabTable(k)}
                                    className="border-bottom"
                                >
                                    <Tab eventKey={'category'} title={'Category'} className="mt-4">
                                        <div>{renderCategoryTable()}</div>
                                    </Tab>
                                    <Tab eventKey={'collection'} title={'Collection'} className="mt-4">
                                        <div>{renderCollectionTable()}</div>
                                    </Tab>
                                </Tabs>
                            )
                            : (renderCategoryTable())
                        }
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default ProductLevelSalesTable;
