import fortress from "./fortress";

export async function brandMetrics(token, params) {
    return fortress.get("brand/data/metrics", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function vPartMetrics(token, params) {
    return fortress.post("brand/vpart/metrics", params, {
        headers: {
            // 'Accept': 'application/json',
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    });
}

export async function summaryMetrics(token, params) {
    return fortress.get("brand/data/summaryMetrics", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function fullSummary(token, params) {
    return fortress.get("brand/data/fullSummary", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function brandTopLine(token, params) {
    return fortress.get("brand/datatable/topLine", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function timelineOverview(token, params) {
    return fortress.get("brand/data/timelineOverview", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function fetchChartData(token, params) {
    return fortress.get("brand/data/timelineOverviewChart", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function brandDataToday(token, params) {
    return fortress.get("brand/data/today", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function comparativeBrand(token, params) {
    return fortress.get("brand/data/comparative", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function comparativeVpart(token, params) {
    return fortress.get("brand/vpart/comparative", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function comparativeCategory(token, params) {
    return fortress.get("brand/category/comparative", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function summarySNS(token, params) {
    return fortress.get("/brand/data/summarySNS", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function snsPerformance(token, params) {
    return fortress.get("brand/data/snsPerformance", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function snsTimeframe(token, params) {
    return fortress.get("brand/data/snsTimeframe", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function snsSubscriptionsPY(token, params) {
    return fortress.get("brand/data/snsSubscriptionsPY", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function snsSubscribersOverTime(token, params) {
    return fortress.get("brand/data/snsSubscribersOverTime", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function productPerformance(token, params) {
    return fortress.get("brand/data/productsPerformance", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function categoryPerformance(token, params) {
    return fortress.get("brand/data/categoryPerformance", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function collectionPerformance(token, params) {
    return fortress.get("brand/data/collectionPerformance", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function bundlePerformanceCharts(token, params) {
    return fortress.get("brand/data/bundlePerformanceCharts", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function bundlePerformanceData(token, params) {
    return fortress.get("brand/data/bundlePerformanceData", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function bundleAggregatedData(token, params) {
    return fortress.get("brand/data/bundleAggregatedData", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function brandReviews(token, params) {
    return fortress.get("brand/reviews", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function topProducts(token, params) {
    return fortress.get("brand/topProducts", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function topSNS(token, params) {
    return fortress.get("brand/topSNS", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function topProductsGrowth(token, params) {
    return fortress.get("brand/topProductsGrowth", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}

export async function blendedRoAS(token, params) {
    return fortress.get("brand/blendedRoAS", {
        headers: { Authorization: `Bearer ${token}` },
        params: params,
    });
}
