import React, { useContext, useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { HeaderContext } from "../../../contexts/HeaderContext";

import BootstrapTablesWrapper from "../BootstrapTablesWrapper";
import WidgetSpinner from "../../../components/helper/WidgetSpinner";

const tableColumns = [
    {
        dataField: "index",
        text: "#",
        sort: false,
        hidden: true,
        type: "number",
    },
    {
        // Combine SKU + Short Title + Image
        dataField: "sku",
        text: "Short Title / SKU",
        sort: true,
        type: "string",
        filter: "textFilter",
        headerFormatter: (column, index, { sortElement, filterElement }) => {
            return (
                <div className="d-flex flex-column w-100">
                    <p className="mb-0">Products {sortElement}</p>
                    <p className="mb-2">
                        <span className="float-left text-sm">SKU</span>
                    </p>
                    {filterElement}
                </div>
            );
        },
        headerStyle: (colum, colIndex) => {
            return { width: "270px" };
        },
        sortValue: (cell, row) => row["sku"], // we use type name to sort.
        filterValue: (cell, row) => `${row["short_title"]} ${row["sku"]}`,
        formatter: (cell, row, rowIndex) => {
            return (
                <div className="d-flex flex-row flex-nowrap align-items-center">
                    <a href={row["asinLink"]} target="_blank" rel="noopener noreferrer">
                        <img
                            className="img-thumbnail rounded mr-2 mb-2"
                            width="45"
                            alt={row["sku"]}
                            src={`${row["image"]}`}
                        ></img>
                    </a>
                    <div className="d-flex flex-column w-100 ml-2">
                        <p className="mb-0">{row["short_title"]}</p>
                        <div className="d-flex flex-row justify-content-between text-sm">
                            <p>{row["sku"]}</p>
                        </div>
                    </div>
                </div>
            );
        },
    },
    {
        dataField: "short_title",
        text: "Short Title",
        type: "string",
        sort: true,
        hidden: true,
    },
    {
        dataField: "subscriptions",
        text: "Subscribers",
        sort: true,
        type: "number",
        formatter: "intFormatter",
    },
    {
        dataField: "wk4_units",
        text: "4 Week Units",
        sort: true,
        type: "number",
        formatter: "intFormatter",
    },
    {
        dataField: "wk4_sales",
        text: "4 Week Revenue",
        sort: true,
        type: "number",
        formatter: "dollarFormatter",
    },
    {
        dataField: "wk8_units",
        text: "+8 Week Units",
        sort: true,
        type: "number",
        formatter: "intFormatter",
    },
];

const defaultSorted = [
    {
        dataField: "subscriptions",
        order: "desc",
    },
];

const SNSPerformanceTable = (props) => {
    const { dataLoading } = useContext(HeaderContext);

    const [tableData, setTableData] = useState();
    const [widgetLoading, setWidgetLoading] = useState(true);

    const {
        showConfigButton = false,
        headerTitle = "SNS Product Performance",
        headerSubtitle = "",
        widgetData = null,
        widgetDataLoading = null,
    } = props;

    useEffect(() => {
        const getData = async () => {
            if (widgetData !== null) {
                setWidgetLoading(true);
                setTableData(widgetData);
                setWidgetLoading(false);
            } else {
                setWidgetLoading(true);
            }
        };

        if (!dataLoading) {
            if (widgetDataLoading === null || widgetDataLoading === false) {
                getData();
            }
        }
    }, [dataLoading, widgetData, widgetDataLoading]);

    const renderTable = () => {
        return (
            <BootstrapTablesWrapper
                data={tableData}
                tableOnly={true}
                keyField="index"
                hasPagination={true}
                hasFilter={true}
                columns={tableColumns}
                defaultSorted={defaultSorted}
            />
        );
    };

    return (
        <Card className="h-100">
            <Card.Header className="bg-white">
                <div className="d-flex flex-row align-items-center">
                    <div>
                        <h5>{headerTitle}</h5>
                        {headerSubtitle !== "" ? <p className="body-1 text-secondary mb-0">{headerSubtitle}</p> : ""}
                    </div>
                    <div className="ml-auto ">
                        {showConfigButton === true ? (
                            <button
                                type="button"
                                className="btn btn-primary btn-sm "
                                onClick={() => {
                                    //TODO: Add Configuration Modal
                                }}
                            >
                                Config
                            </button>
                        ) : null}
                    </div>
                </div>
            </Card.Header>

            <Card.Body className="pb-0" style={{ minHeight: props.minHeight ?? "500px" }}>
                {widgetLoading ? (
                    <div className="w-100 h-100">
                        <WidgetSpinner />
                    </div>
                ) : (
                    <div className="w-100">{renderTable()}</div>
                )}
            </Card.Body>
        </Card>
    );
};

export default SNSPerformanceTable;
